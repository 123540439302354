<template>
    <article
        class="card-notification"
        :class="!readed ? 'bg-white ring-2 ring-green-500' : 'bg-white'"
        :style="!readed ? 'box-shadow: 0 0 5px rgb(74 222 128 / 1), 0 8px 10px -6px rgb(0 0 0 / 0.1);' : ''"
    >
        <div class="card-notification__author">
            <NuxtLink
                no-prefetch
                v-if="
                    user && notification.icon_user && notification.action !== 'web_many_likes_to_my_comment'
                "
                :to="'/dashboard/' + user.id"
                rel="follow"
                class="card-notification__author-image"
            >
                <MiscUserPic :user="user" />
                <picture v-if="icon.topicon" class="card-notification__author-image-top-icon">
                    <img :src="icon.topicon" alt="top icon" />
                </picture>
                <picture
                    v-if="icon.subicon"
                    class="card-notification__author-image-sub-icon"
                    :class="icon.subicon.style"
                >
                    <img :src="icon.subicon.data" alt="sub icon" />
                </picture>
            </NuxtLink>
            <div v-else rel="follow" class="card-notification__author-image">
                <img
                    v-if="typeof icon.main === 'string'"
                    class="card-notification__author-image-main"
                    :src="icon.main"
                    alt="icon"
                />
                <picture v-if="icon.topicon" class="card-notification__author-image-top-icon">
                    <img :src="icon.topicon" alt="top icon" />
                </picture>
                <picture
                    v-if="icon.subicon"
                    class="card-notification__author-image-sub-icon"
                    :class="icon.subicon.style"
                >
                    <img :src="icon.subicon.data" alt="sub icon" />
                </picture>
            </div>
        </div>
        <div class="about">
            <div class="top">
                <p class="time">{{ getDateDifference(notification.created_at) }}</p>
                <div class="top__options">
                    <button
                        type="button"
                        class="top__options-button"
                        @click="() => handleOptionsMenuToggle(true)"
                    >
                        <img :src="$assets.gray.dropdownMenuDots" alt="dropdown-icon" />
                    </button>
                    <transition name="drop-from-right">
                        <DropdownOptions
                            v-if="showOptionsMenu"
                            :options="optionsMenu"
                            class="top__dropdown"
                            @close="() => handleOptionsMenuToggle(false)"
                        />
                    </transition>
                </div>
            </div>

            <div class="action-and-button">
                <div class="action-and-button__action" v-html="notificationText" />
                <NuxtLink
                    no-prefetch
                    v-if="!isReportOrExpired && variant && variant.name"
                    class="action-and-button__button"
                    :to="variant.path"
                    type="button"
                    rel="follow"
                >
                    Ver {{ variant.name }}
                </NuxtLink>
            </div>
        </div>
    </article>
</template>

<script lang="ts">
import { intervalToDuration, formatDuration } from 'date-fns'
import { es } from 'date-fns/locale'
import type { Models } from '~/types/models'
import type { PropType } from 'vue'

import { useRootStore } from '~/store/root'

import { useAuthStore } from '~/store/auth'
import type { Api } from '~~/global'

import { useVariantsDictionary } from '~/composables/VariantsDictionary'

type Icons = {
    topicon?: string
    main?: string | { user: boolean }
    subicon?: {
        data: string
        style: string
    } | null
}

export default defineComponent({
    name: 'CardNotifications',
    props: {
        notification: {
            type: Object as PropType<Models.Notification>,
            required: true,
        },
        readed: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        const isReportOrExpired =
            this.notification.action === 'expired_report' ||
            this.notification.action === 'web_rejected_report' ||
            this.notification.action === 'web_approved_report' ||
            this.notification.action === 'expired' ||
            this.notification.action === 'web_expired_post' ||
            this.notification.type === 'expired' ||
            this.notification.type === 'web_expired_post' ||
            this.notification.type === 'report'

        // Define Variant

        const { variants } = useVariantsDictionary()

        let variant: { path: string; name: string; modelPath?: string } = { path: 'null', name: 'null' }

        if (
            this.notification.models[0]?.attributes.report ||
            this.notification.models[0]?.attributes.conversation
        ) {
            variant = {
                path: '/mensajeria?id=' + this.notification.models[0]?.attributes.conversation?.id,
                name: 'mensaje',
            }
        } else {
            let key, keyES, attributes

            if (this.notification.models.length && this.notification.models[0]?.attributes) {
                key = Object.keys(this.notification.models[0]?.attributes)

                keyES = variants[key[0] as 'offer']

                attributes = this.notification.models[0]?.attributes[key[0] as 'offer' | 'coupon']
            }

            if (this.notification.action === 'reply') {
                variant = {
                    path: `${keyES?.path}/${attributes?.slug}#reply-${this.notification.models[0]?.attributes.comment?.id}`,
                    name: 'comentario',
                }
            } else if (
                this.notification.type === 'comment' ||
                this.notification.action === 'comment' ||
                this.notification.action === 'new_comment' ||
                this.notification.action === 'comment_liked' ||
                this.notification.action === '5_new_comment' ||
                this.notification.action === 'approved_comment' ||
                this.notification.action === 'rejected_comment' ||
                this.notification.action === 'rejected_comment_replied' ||
                this.notification.action === 'web_approved_comment' ||
                this.notification.action === 'web_like_to_my_comment' ||
                this.notification.action === 'has_less_than_5_comments' ||
                this.notification.action === 'has_more_than_5_comments' ||
                this.notification.action === 'web_answer_to_my_comment' ||
                this.notification.action === 'web_many_likes_to_my_comment'
            ) {
                if (this.notification.models[0]?.attributes.comment?.permalinkshort) {
                    const permalink = new URL(this.notification.models[0]?.attributes.comment?.permalinkshort)

                    variant = {
                        path: permalink.pathname + permalink.hash,
                        name: 'comentario',
                    }
                } else
                    variant = {
                        path: `${keyES?.path}/${attributes?.slug}#comment-${this.notification.models[0]?.attributes.comment?.id}`,
                        name: 'comentario',
                    }
            } else if (
                this.notification.action === 'coins_comment' ||
                (this.notification.action === 'web_get_coins' &&
                    (this.notification.type === 'coupon' || this.notification.type === 'offer'))
            ) {
                variant = {
                    path: `/dashboard/monedas`,
                    modelPath: `${keyES?.path}/${attributes?.slug}#reply-${this.notification.models[0]?.attributes.comment?.id}`,
                    name: 'monedas',
                }
            } else if (
                this.notification.action === 'login' ||
                this.notification.action === 'antiquity_month' ||
                this.notification.action === 'antiquity_six_months' ||
                this.notification.action === 'anniversary' ||
                this.notification.action === 'coins_popular_250' ||
                this.notification.action === 'coins_popular_500' ||
                this.notification.action === 'signup' ||
                this.notification.action === 'expired_report'
            ) {
                variant = {
                    path: `/dashboard/monedas`,
                    name: 'monedas',
                }
            } else if (this.notification?.type === 'offer') {
                const path = this.notification.models[0]?.attributes.offer?.without_detail
                    ? `/${this.$lang.routes.brands}/${this.notification.models[0]?.attributes.offer.store.slug}`
                    : `/${this.$lang.routes.offers}/${attributes?.slug}`

                variant = { path, name: 'oferta' }
            } else if (this.notification?.type === 'coupon') {
                const path =
                    this.notification.models[0]?.attributes.coupon?.is_admin ||
                    this.notification.models[0]?.attributes.coupon?.without_detail
                        ? `/${this.$lang.routes.brands}/${this.notification.models[0]?.attributes.coupon.store.slug}`
                        : `/${this.$lang.routes.coupons}/${attributes?.slug}`

                variant = { path, name: 'cupón' }
            } else if (this.notification?.type === 'forum') {
                variant = { path: `/foros/${attributes?.slug}`, name: 'foro' }
            } else if (this.notification?.type === 'novelty') {
                variant = { path: `/novedades/${attributes?.slug}`, name: 'novedad' }
            }
        }

        // Define Notification Text

        let notificationText: string

        if (isReportOrExpired) {
            if (variant.path || variant.modelPath) {
                notificationText = this.notification.message.replace(
                    "href=''",
                    `href="${variant.modelPath || variant.path}"`,
                )
            } else {
                notificationText = this.notification.message.replace(/<a/g, '<b').replace(/<\/a>/g, '</b>')
            }
        } else {
            const has_action_skip = ['rejected']

            const has_word = ['novedad', 'novedades', 'comentario', 'publicación', 'chat', 'oferta', 'cupón']

            const splitted_message = this.notification.message.split(' ')

            if (
                !has_action_skip.some((action) => this.notification.action.includes(action)) &&
                has_word.some((wordA) =>
                    splitted_message.find((wordB) => wordA.includes(wordB) || wordB.includes(wordA)),
                )
            ) {
                splitted_message.forEach((word, index) => {
                    if (has_word.some((el) => word.includes(el))) {
                        splitted_message[index] = `<a href="${
                            variant.modelPath || variant.path
                        }" rel="nofollow">${word}</a>`
                    }
                })
            }

            notificationText = splitted_message.join(' ')
        }

        // Define User Data

        const user = this.notification.models[0]?.attributes?.user

        // Define Notification icon

        let icon: Icons

        icon = {
            main: this.$assets.illustration.threadPublishSuccess,
        }

        switch (true) {
            case this.notification.type === 'forum' && this.notification.action === 'approved':
                icon = {
                    main: this.$assets.illustration.threadPublishSuccess,
                    subicon: {
                        data: this.$assets.white.successClean,
                        style: 'bg-site-primary',
                    },
                }
                // VOTED
                break
            case this.notification.action === 'liked':
                icon = {
                    main: 'https://randomuser.me/api/portraits/women/19.jpg',
                    subicon: {
                        data: this.$assets.white.voteup,
                        style: 'bg-site-primary',
                    },
                }
                // EXPIRADA - CHECKEAR ACTION QUE SEA EXPIRED
                break
            case this.notification.action === 'web_expired_post' || this.notification.action === 'expired':
                icon = {
                    main: this.$assets.illustration.offerExpired,
                    subicon: {
                        data: this.$assets.white.expired,
                        style: 'bg-orange-500',
                    },
                }
                break
            case this.notification.action === 'reply' ||
                this.notification.action === 'web_new_message_in_inbox':
                icon = {
                    main: this.$assets.illustration.comment,
                }
                break
            case this.notification.action === 'comment' ||
                this.notification.action === 'web_approved_comment':
                icon = {
                    main: this.$assets.illustration.comment,
                    subicon: {
                        data: this.$assets.white.successClean,
                        style: 'bg-site-primary',
                    },
                }
                break
            case this.notification.action === 'approved' &&
                (this.notification.type === 'report' || this.notification.type === 'web_expired_post'):
                icon = {
                    main: this.$assets.illustration.acceptedGreen,
                }
                break
            case this.notification.action.includes('approved') ||
                this.notification.action.includes('published'):
                icon = {
                    main: this.$assets.illustration.publishedSuccess,
                }
                break
            case this.notification.action === '5_new_comment':
                icon = {
                    main: this.$assets.illustration.comment,
                    subicon: {
                        data: this.$assets.white.equis,
                        style: 'bg-site-primary',
                    },
                }
                // Thread publicado
                break
            case this.notification.action === 'published':
                icon = {
                    main: this.$assets.illustration.threadPublishSuccess,
                    subicon: {
                        data: this.$assets.white.successClean,
                        style: 'bg-site-primary',
                    },
                }
                // BLOG PUBLICADO
                break
            case this.notification.action === 'new_novelty' ||
                this.notification.action === 'web_new_news_published':
                icon = {
                    main: this.$assets.illustration.newBlogEntry,
                    subicon: {
                        data: this.$assets.white.successClean,
                        style: 'bg-blue-400',
                    },
                }
                // LOGIN
                break
            case this.notification.action === 'login':
                icon = {
                    main: this.$assets.illustration.coinDefault,
                    subicon: {
                        data: this.$assets.white.successClean,
                        style: 'bg-orange-500',
                    },
                }
                // REGISTRARSE
                break
            case this.notification.action === 'signup':
                icon = {
                    main: this.$assets.illustration.coinDefault,
                    subicon: {
                        data: this.$assets.white.successClean,
                        style: 'bg-orange-500',
                    },
                }
                // 10 MONEDAS POR COMENTAR
                break
            case this.notification.action === 'new_comment':
                icon = {
                    main: this.$assets.illustration.coinDefault,
                    subicon: {
                        data: this.$assets.white.successClean,
                        style: 'bg-orange-500',
                    },
                }
                // GANA MONEDAS POR COMENTAR
                break
            case this.notification.action === 'coins_comment' || this.notification.action === 'web_get_coins':
                icon = {
                    main: this.$assets.illustration.coinDefault,
                    subicon: {
                        data: this.$assets.white.successClean,
                        style: 'bg-orange-500',
                    },
                }
                break
            case this.notification.action === 'web_unpopular' || this.notification.action === 'minus_25':
                icon = {
                    main: this.$assets.illustration.coinBlue,
                    subicon: {
                        data: this.$assets.white.minus,
                        style: 'bg-blue-700',
                    },
                }
                break
            case this.notification.action === 'web_banned':
                icon = {
                    main: this.$assets.illustration.banned,
                }
                break
            case this.notification.action.includes('rejected'):
                icon = {
                    main: this.$assets.illustration.rejected,
                }
                // PRIMER GRADO
                break
            case this.notification.action === 'web_first_grade':
                icon = {
                    main: this.$assets.illustration.firstGrade,
                    subicon: null,
                }
                // POPULAR
                break
            case this.notification.action === 'web_popular_250' || this.notification.action === 'popular_250':
                icon = {
                    main: this.$assets.illustration.popularOffer,
                    subicon: null,
                }
                // MUY POPULAR || MUY POPULAR DE OTRA PERSONA
                break
            case this.notification.action === 'web_popular_500' ||
                this.notification.action === 'popular_500' ||
                this.notification.action === 'web_other_offers_popular_500':
                icon = {
                    main: this.$assets.illustration.popularOffer,
                    subicon: {
                        data: this.$assets.white.equis,
                        style: 'bg-red-500',
                    },
                }
                // COMENTARIO LIKEADO
                break
            case this.notification.action === 'web_like_to_my_comment' ||
                this.notification.action.includes('comment'):
                icon = {
                    main: this.$assets.illustration.comment,
                    subicon: {
                        data: this.$assets.white.voteup,
                        style: 'bg-site-primary',
                    },
                }
                // WIN REACH
                break
            case this.notification.action === 'antiquity_six_months':
                icon = {
                    main: this.$assets.illustration.coinRed,
                    subicon: {
                        data: this.$assets.white.successClean,
                        style: 'bg-orange-500',
                    },
                }
                // SENIORITY
                break
            case this.notification.action === 'antiquity_month':
                icon = {
                    main: this.$assets.illustration.coinGreenAndRed,
                    subicon: {
                        data: this.$assets.white.successClean,
                        style: 'bg-orange-500',
                    },
                }
                // ANIVERSARIO
                break
            case this.notification.action === 'anniversary':
                icon = {
                    main: this.$assets.illustration.coinGreenAndRed,
                    subicon: {
                        data: this.$assets.white.successClean,
                        style: 'bg-orange-500',
                    },
                }
                break
            case this.notification.action.includes('coins') || this.notification.action === 'expired_report':
                icon = {
                    main: this.$assets.illustration.coinDefault,
                }
        }

        const { setSiteNotification, setGenericModal } = useRootStore()
        const { SessionToken } = useAuthStore()
        return {
            SessionToken,
            setGenericModal,
            setSiteNotification,
            icon,
            user,
            notificationText,
            variant,
            isReportOrExpired,
            links: [] as HTMLAnchorElement[],
            showOptionsMenu: false,
            dropdown: {
                show: {
                    options: false,
                },
            },
        }
    },
    computed: {
        optionsMenu(): any {
            const remove = {
                title: this.$lang.components.cardNotifications.remove,
                icon: this.$assets.gray.remove,
                description: this.$lang.components.cardNotifications.delete_notification,
                handler: () => this.removeHandler(),
            }
            const muteAction = {
                title: this.$lang.components.cardNotifications.deactivate,
                icon: this.$assets.gray.deactivate,
                description: this.$lang.components.cardNotifications.stop_receiving_notifications_like_this,
                handler: () => this.muteActionHandler(),
            }
            if (this.notification.models.length) {
                return [remove, muteAction]
            } else {
                return [remove]
            }
        },
    },
    mounted() {
        if (this.$router) {
            this.addListeners()
        }
    },
    beforeDestroy() {
        if (this.$router) {
            this.removeListeners()
        }
    },
    updated() {
        if (this.$router) {
            this.removeListeners()
            this.$nextTick(() => {
                this.addListeners()
            })
        }
    },
    methods: {
        muteActionHandler() {
            this.setGenericModal({
                type: 'confirm',
                info: {
                    text: this.$lang.components.cardNotifications.want_stop_receiving_notifications_like_this,
                    continue: {
                        text: this.$lang.components.cardNotifications.confirm,
                        handler: async () => {
                            let actionToMute = this.notification.action
                            const is_coin = [
                                'coins_popular_250',
                                'coins_popular_500',
                                'antiquity_month',
                                'antiquity_six_months',
                                'anniversary',
                                'signup',
                                'expired_report',
                                'login',
                            ]
                            if (is_coin.some((type) => this.notification.action.includes(type))) {
                                actionToMute = 'web_get_coins'
                            }
                            const { buildHeaders, baseURL, endpoints } = useApiConfig()

                            const response = await $fetch<Api.Responses.User.SuccessNotificationsActionMuted>(
                                endpoints.notifications.muteModel.path,
                                {
                                    headers: buildHeaders(this.SessionToken),
                                    method: 'POST',
                                    baseURL,
                                    body: {
                                        action: actionToMute,
                                        type: 0,
                                    },
                                },
                            )

                            if (response.feedback === 'resource_created') {
                                this.$emit('removeitem', this.notification.models[0].model_id, true)
                                this.setSiteNotification({
                                    text: this.$lang.components.cardNotifications.silenced_notification,
                                    duration: 3000,
                                })
                            } else {
                                this.setSiteNotification({
                                    text: this.$lang.components.cardNotifications.error_silence_notification,
                                    duration: 3000,
                                    type: 'error',
                                })
                            }
                        },
                    },
                    cancel: {
                        text: this.$lang.components.cardNotifications.cancel,
                        handler: () => null,
                    },
                },
            })
        },
        removeHandler() {
            this.setGenericModal({
                type: 'confirm',
                info: {
                    text: this.$lang.components.cardNotifications.want_delete_notification,
                    continue: {
                        text: this.$lang.components.cardNotifications.delete,
                        handler: async () => {
                            const { buildHeaders, baseURL, endpoints } = useApiConfig()
                            const response = await $fetch<Api.Responses.User.SuccessNotificationsDeleted>(
                                endpoints.notifications.delete.path + '/' + this.notification.id,
                                {
                                    headers: buildHeaders(this.SessionToken),
                                    method: 'DELETE',
                                    baseURL,
                                },
                            )

                            if (response.feedback === 'data_deleted_success') {
                                this.$emit('removeitem', this.notification.id)
                                this.setSiteNotification({
                                    text: this.$lang.components.cardNotifications.deleted_notification,
                                    duration: 3000,
                                    dismissButtonText: this.$lang.components.cardNotifications.hide,
                                    type: 'success',
                                })
                            } else {
                                this.setSiteNotification({
                                    text: this.$lang.components.cardNotifications.error_delete_notification,
                                    duration: 3000,
                                    dismissButtonText: this.$lang.components.cardNotifications.hide,
                                    type: 'error',
                                })
                            }
                            this.setGenericModal(null)
                        },
                    },
                    cancel: {
                        text: this.$lang.components.cardNotifications.cancel,
                        handler: () => this.setGenericModal(null),
                    },
                },
            })
        },
        getDateDifference(date: any) {
            const duration = intervalToDuration({
                start: new Date(date as any),
                end: new Date(),
            })

            const units = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds']
            const nonzero = Object.entries(duration)
                .filter(([_, value]) => value)
                .map(([unit, _]) => unit)

            return formatDuration(duration, {
                format: units.filter((i) => new Set(nonzero).has(i)).slice(0, 1) as any,
                delimiter: ', ',
                locale: es,
            })
        },
        dropdownToggle(show: boolean, name: string): void {
            this.dropdown.show[name as 'options'] = show
        },
        handleOptionsMenuToggle(show: boolean): void {
            this.showOptionsMenu = show
        },
        navigate(event: any) {
            const href = event.target.getAttribute('href')
            const target = event.target.getAttribute('target')
            if (href && href[0] === '/' && target !== '_blank') {
                event.preventDefault()
                this.$router && this.$router.push(href)
            }
        },

        addListeners() {
            this.links = this.$el.getElementsByTagName('a') as any
            for (let i = 0; i < this.links.length; i++) {
                this.links[i].addEventListener('click', this.navigate, false)
            }
        },

        removeListeners() {
            for (let i = 0; i < this.links.length; i++) {
                this.links[i].removeEventListener('click', this.navigate, false)
            }
            this.links = []
        },
    },
})
</script>

<style lang="postcss" scoped>
.card-notification {
    @apply flex rounded-xl px-1 py-2;
    &__author {
        @apply flex justify-start gap-x-1 p-4;
        &-image {
            @apply relative mx-auto h-14 w-14 flex-none rounded-full;
            &-main {
                @apply relative z-20 h-full w-full rounded-full border-2 border-white object-cover;
            }
            &-top-icon {
                @apply absolute -left-3 -top-3 rounded-full border-2 border-white;
                img {
                    @apply h-8 w-8 rounded-full;
                }
            }
            &-sub-icon {
                @apply absolute -bottom-1.5 -right-1.5 z-30 rounded-full border-2 border-white;
                img {
                    @apply h-5 w-5 p-1;
                }
            }
        }
    }
    .about {
        @apply flex w-full flex-col;
        .top {
            @apply flex justify-end gap-1 text-xs text-gray-800;
            &__options {
                @apply relative;
            }
            &__dropdown {
                @apply absolute right-0 top-full w-[200px] rounded-xl;
            }
            &__options-button {
                @apply h-4 w-4;
                img {
                    @apply h-full w-full rotate-90 object-contain;
                }
            }
        }
        .action-and-button {
            @apply block space-y-1.5 text-sm sm:text-base;
            &__action {
                @apply mb-2 block overflow-hidden break-words break-all text-[#959199];

                :deep(a) {
                    @apply cursor-pointer font-semibold text-site-primary hover:underline;
                }
                :deep(b) {
                    @apply break-words;
                    word-break: break-word;
                }
            }
            &__button {
                @apply block max-w-max rounded-full border border-site-primary p-1 px-5 text-sm font-medium text-site-primary transition duration-100 hover:bg-site-primary hover:text-white;
            }
        }
    }
}
</style>
