<template>
    <div class="notificaciones-index-page">
        <div class="notificaciones-index-page__wrapper">
            <div class="head">
                <div class="head-title">
                    <picture class="head-title__image">
                        <img :src="$assets.gray.notifications" alt="Icono de notificaciones" />
                    </picture>
                    {{ $lang.pages.notificaciones.notifications }}
                </div>
                <NuxtLink
                    no-prefetch
                    :to="`/${$lang.routes.dashboard}/${$lang.routes.settings}#notificaciones`"
                >
                    <img
                        class="head-tools-image"
                        :src="$assets.gray.tools"
                        alt="Icono de herramientas"
                        height="10px"
                        width="10px"
                    />
                </NuxtLink>
            </div>

            <div class="notification-wrapper">
                <h2 class="title">{{ $lang.pages.notificaciones.no_readed }}</h2>
                <div v-if="unreadedData.notifications.length">
                    <ul class="mb-3 space-y-3">
                        <client-only>
                            <li v-for="(item, key) in unreadedData.notifications" :key="key">
                                <NotificationCard
                                    :notification="item"
                                    :readed="false"
                                    @removeitem="removeItem"
                                />
                            </li>
                        </client-only>
                    </ul>
                    <ButtonLoadMore
                        v-if="
                            unreadedData.pagination.last_page &&
                            unreadedData.pagination.current_page < unreadedData.pagination.last_page
                        "
                        :handler="() => getMoreNotifications(false)"
                        :loading="unreadedData.loading"
                    />
                </div>
                <div v-else class="no-notifications">
                    <img
                        :src="$assets.illustration.notificationsEmpty"
                        :alt="$lang.pages.notificaciones.no_notifications"
                    />
                    <span>{{ $lang.pages.notificaciones.no_unreaded }}</span>
                </div>
            </div>

            <div v-if="readedData.notifications.length" class="notification-wrapper">
                <h2 class="title">{{ $lang.pages.notificaciones.readed }}</h2>
                <ul class="mb-3 space-y-3">
                    <li v-for="(item, key) in readedData.notifications" :key="key">
                        <NotificationCard :notification="item" @removeitem="removeItem" />
                    </li>
                </ul>
                <ButtonLoadMore
                    v-if="
                        readedData.pagination.last_page &&
                        readedData.pagination.current_page < readedData.pagination.last_page
                    "
                    :handler="() => getMoreNotifications(true)"
                    :loading="readedData.loading"
                />
            </div>

            <div
                v-if="!unreadedData.notifications.length && !readedData.notifications.length"
                class="no-notifications"
            >
                <span>{{ $lang.pages.notificaciones.no_notifications_by_now }}</span>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useAuthStore } from '~/store/auth'
import type { Models } from '~/types/models'

import type { Api } from '~~/global'

definePageMeta({
    middleware: ['auth-required'],
})

const AuthStore = useAuthStore()

const { $lang } = useNuxtApp()

const { buildHeaders, baseURL, endpoints } = useApiConfig()

const getNotifications = (seen: boolean, page: number = 1, per_page: number = 5) =>
    $fetch<Api.Responses.Pages.Notifications>(endpoints.general.notifications.path, {
        headers: buildHeaders(AuthStore.SessionToken),
        baseURL,
        method: 'GET',
        params: {
            user_id: AuthStore.UserData?.id,
            read: seen,
            per_page,
            page,
        },
    })

const { data: responsesData, error } = await useAsyncData('notifications-data', async () => {
    const readed = await getNotifications(true)
    const unreaded = await getNotifications(false)

    return {
        readed,
        unreaded,
    }
})

if (
    error.value ||
    !responsesData.value ||
    responsesData.value.readed.error ||
    responsesData.value.unreaded.error
) {
    throw createError({
        statusCode: 404,
        message: 'Error data',
    })
}

const readedData = reactive({
    notifications: responsesData.value.readed.data.results,
    pagination: responsesData.value.readed.data.meta,
    info: responsesData.value.readed,
    loading: false,
})
const unreadedData = reactive({
    notifications: responsesData.value.unreaded.data.results,
    pagination: responsesData.value.unreaded.data.meta,
    info: responsesData.value.unreaded,
    loading: false,
})

const Route = useRoute()
const {
    public: { origin },
} = useRuntimeConfig()

const title = $lang.pages.notificaciones.title_seo
const description = $lang.pages.notificaciones.description_seo

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

const getMoreNotifications = async (readed: boolean) => {
    const page = (readed ? readedData.pagination.current_page : unreadedData.pagination.current_page) + 1

    if (readed) {
        readedData.loading = true
    } else {
        unreadedData.loading = true
    }

    const response = await getNotifications(readed, page)

    if (readed) {
        readedData.info = response
        readedData.pagination = response.data.meta
        readedData.notifications = [...readedData.notifications, ...response.data.results]
        readedData.loading = false
    } else {
        unreadedData.info = response
        unreadedData.pagination = response.data.meta
        unreadedData.notifications = [...unreadedData.notifications, ...response.data.results]
        unreadedData.loading = false

        markAsRead(response.data.results)
    }
}

const removeItem = (id: number, type: boolean = false) => {
    unreadedData.notifications = unreadedData.notifications.filter((item: Models.Notification) => {
        if (type && id === item.models[0].model_id) {
            return false
        }
        return item.id !== id
    })
}

const markAsRead = (notifications: Models.Notification[]) => {
    const bodyFormData = new FormData()

    notifications.forEach((notification: Models.Notification) => {
        if (notification.read_at === null) {
            bodyFormData.append('ids[]', notification.id.toString())
        }
    })

    if (bodyFormData.has('ids[]')) {
        $fetch<Api.Responses.User.SuccessMessagesMarkAsRead>(endpoints.notifications.MarkAsRead.path, {
            headers: buildHeaders(AuthStore.SessionToken),
            method: 'POST',
            baseURL,
            body: bodyFormData,
            onResponse() {
                AuthStore.updateActivitiesCount('notifications')
            },
        }).catch((e) => e.data)
    }
}

onMounted(() => {
    markAsRead(unreadedData.info.data.results)
})
</script>

<style lang="postcss" scoped>
.notificaciones-index-page {
    @apply container mb-10 mt-7;
    &__wrapper {
        @apply mx-auto max-w-3xl space-y-5;
        .head {
            @apply flex items-center justify-between;
            &-title {
                @apply flex items-center gap-3 text-2xl font-medium text-gray-800;
                &__image {
                    @apply rounded-xl bg-site-primary p-2;
                    img {
                        @apply h-5;
                    }
                }
            }
            &-tools-image {
                @apply h-8 w-8;
            }
        }
        .notification-wrapper {
            .title {
                @apply mb-3 text-lg font-semibold;
            }
        }

        .no-notifications {
            @apply flex flex-col items-center justify-center;
            span {
                @apply text-center text-lg font-medium text-gray-800 md:text-xl;
            }
        }
    }
}
</style>
